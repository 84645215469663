<!--
File: UserEditForm.vue
Description: form for adding/editing a user.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field :class="getClass('email')">
          <label :for="email">{{ $t('users.email') }}</label>
          <md-input :id="email" v-model="email" type="email" data-vv-name="email" required
            v-validate="modelValidations.email" />
        </md-field>

        <md-field :class="getClass('firstName')">
          <label :for="firstName">{{ $t('users.firstname') }}</label>
          <md-input :id="firstName" v-model="firstName" type="text" data-vv-name="firstName" required
            v-validate="modelValidations.firstName" />
        </md-field>

        <md-field :class="getClass('lastName')">
          <label :for="lastName">{{ $t('users.lastname') }}</label>
          <md-input :id="lastName" v-model="lastName" type="text" data-vv-name="lastName" required
            v-validate="modelValidations.lastName" />
        </md-field>

        <BaseDropdown :class="getClass('userGroup')" :label="$t('users.group')" v-model="userGroup" :items="groups"
          data-vv-name="userGroup" v-validate="modelValidations.userGroup" :displayField="displayField" required />

        <md-radio v-for="role in roles" :key="role" v-model="userRole" :value="role">
          {{ $t(`users.role_${role}`) }}
        </md-radio>
      </div>

      <span v-if="saveBtnDisabled" class="md-error">
        {{ $t('messages.errors_count', { count: errors.count() }) }}
      </span>
    </template>

    <template slot='footer'>
      <md-button v-if="showPassResetBtn" class="md-danger" native-type="submit" @click.native.prevent="passwordReset">
        {{ $t('buttons.password_reset') }}
      </md-button>
      <span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; </span>
    </template>
  </modal>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { Modal, BaseDropdown } from '@/pages/Components'
import { getLang } from '@/api'
import permissions from "@/mixins/permissionsMixin"
import messages from '@/mixins/messagesMixin'

export default {
  name: 'user-edit-form',
  mixins: [permissions, messages],

  data() {
    return {
      formName: 'UserEditForm',
      eligible: false,

      email: null,
      lastName: null,
      firstName: null,
      userGroup: null,
      userRole: 'user',
      roles: ['admin', 'user'],

      initialFormState: null,
      modelValidations: {
        email: { required: true, email: true },
        firstName: { required: true, min: 3 },
        lastName: { required: true, min: 3 },
        userGroup: { required: true, numeric: true, min_value: 1 },
      }
    }
  },

  props: {
    userId: null
  },

  components: {
    Modal,
    BaseDropdown
  },

  async mounted() {
    // Check if we are eligible to view the form
    this.eligible = await this.checkIfScreenAllowed();
    if (!this.eligible) {
      this.$emit('close');
      return;
    };

    if (this.userId) { // Load data on existing user
      const theUser = await this.loadUser(this.userId);

      this.email = theUser.email;
      this.lastName = theUser.last_name;
      this.firstName = theUser.first_name;
      this.userGroup = theUser.group_id;
      this.userRole = theUser.role || 'user';
    }
    this.$nextTick(async () => {
      this.initialFormState = this.getCurrentState;
      await this.$validator.validateAll();
    });
    await this.loadUserGroups({});
  },

  methods: {
    ...mapActions({
      loadUserGroups: 'ReferenceData/LOAD_GROUP_LIST',
      loadUser: 'ReferenceData/LOAD_USER_BY_ID',
      addUser: 'ReferenceData/ADD_NEW_USER',
      editUser: 'ReferenceData/UPDATE_USER',
      passRecovery: 'PASSWORD_RECOVERY',
      highlightRow: 'HIGHLIGHT_ROW'
    }),

    async passwordReset() {
      try {
        await this.passRecovery(this.email)
        this.successMessage('', this.$t('login.recovery_mail_was_sent'))
      } catch (err) {
        this.errorMessage(`${this.$t('login.recovery_mail_was_not_sent')} ${err}`)
      }
      this.$emit('close')
    },

    async validate() {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return

      const theItem = this.getCurrentState;
      let errDesc = '';
      let newUserId;
      const action = !this.userId ? this.addUser : this.editUser;
      const payload = !this.userId ? theItem : { id: this.userId, theItem };
      try {
        const res = await action(payload)
        newUserId = res?.id;
      } catch (err) {
        errDesc = err.message || this.$t('messages.unknown_error')
      }

      this.$nextTick(() => this.$validator.reset())
      this.$emit('close')
      await this.savedMessage(errDesc, this.$t('users.role_user'), `${this.firstName} ${this.lastName}`)
      this.highlightRow(newUserId)
    },
  },

  computed: {
    ...mapState({
      groups: (state) => state.ReferenceData.group_list,
    }),

    screenTitle() {
      return this.userId ? this.$t('screen_titles.user_upd') : this.$t('screen_titles.user_add')
    },

    getCurrentState() {
      return {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        group_id: Number(this.userGroup),
        role: this.userRole
      }
    },

    hasUnsavedChanges() {
      if (!this.initialFormState) return false

      return this.firstName !== this.initialFormState.first_name ||
        this.lastName !== this.initialFormState.last_name ||
        this.email !== this.initialFormState.email ||
        this.userRole !== this.initialFormState.role ||
        Number(this.userGroup) !== this.initialFormState.group_id
    },

    displayField() {
      return `name_${getLang()}`;
    },

    showPassResetBtn() {
      return this.userId && this.isBtnAllowed('PassResetButton') && !this.errors.items.some(item => item.field === 'email');
    }
  },
}
</script>